'use strict';

import http from '@/utils/http';

export default {
  getInfo(code, tenant) {
    return http.get(`/issmart-tenant/tenant/getByCode/${tenant}`, { code });
  },
  addInfo(code, data) {
    return http.post('/issmart-tenant/tenant', data, { code });
  },
  updateInfo(code, data) {
    return http.put('/issmart-tenant/tenant', data, { code });
  },
  getEmail(code) {
    return http.get('/issmart-messages/sysEmailProfile/page', { code });
  },

  //保存服务渠道的邮件配置信息
  saveEmailChannel(data) {
    if (data.id) {
      return http.put(`/issmart-messages/sysEmailProfile`, data);
    } else {
      return http.post(`/issmart-messages/sysEmailProfile`, data);
    }
  },
  //获取服务渠道的短信通道列表
  getSMSChannelList(data) {
    return http.get(`/issmart-messages/msgPassageAccount/${data}`);
  },
  //获取服务渠道的短信配置信息
  getSMSChannel() {
    return http.get(`/issmart-messages/msgPassageAccount/page`);
  },
  //保存服务渠道的短信配置信息
  saveSMSChannel(data) {
    if (data.id) {
      return http.put(`/issmart-messages/msgPassageAccount`, data);
    } else {
      return http.post(`/issmart-messages/msgPassageAccount`, data);
    }
  },
  //获取服务渠道的微信配置信息
  getWeChatChannel() {
    return http.get(`/issmart-messages/sysWechatProfile/page`);
  },
  //保存服务渠道的微信配置信息
  saveWeChatChannel(data) {
    if (data.id) {
      return http.put(`/issmart-messages/sysWechatProfile`, data);
    } else {
      return http.post(`/issmart-messages/sysWechatProfile`, data);
    }
  },
};
