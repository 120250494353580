<template>
  <a-tabs type="card">
    <a-tab-pane key="info" tab="企业信息">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        :validate-trigger="['change', 'blur']"
      >
        <a-form-item label="企业名称" v-bind="infoRules.name">
          <a-input v-model:value="info.name" placeholder="请输入企业名称" />
        </a-form-item>
        <a-form-item label="地址" v-bind="infoRules.address">
          <a-input v-model:value="info.address" placeholder="请输入地址" />
        </a-form-item>
        <a-form-item label="电话" v-bind="infoRules.phone">
          <a-input v-model:value="info.phone" placeholder="请输入电话号码" />
        </a-form-item>
        <a-form-item label="邮箱" v-bind="infoRules.email">
          <a-input v-model:value="info.email" placeholder="请输入邮箱地址" />
        </a-form-item>
        <a-form-item
          label="LOGO图"
          v-bind="infoRules.logo"
          extra="请上传大小不超过1M，格式为JPG，JPEG或PNG的图片"
        >
          <iss-image-upload
            v-model:value="info.logo"
            list-type="picture-card"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 15, offset: 6 }" class="ta-right">
          <a-space>
            <a-button type="primary" @click="handleClickByInfo">保存</a-button>
            <a-button @click="handleClickByInfoReset">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-tab-pane>
    <a-tab-pane key="channel" tab="服务渠道">
      <a-tabs
        v-model:active-key="activeKey"
        tab-position="left"
        class="iss-tabs"
      >
        <a-tab-pane key="email" tab="邮件配置">
          <a-form layout="vertical" class="iss-form">
            <a-card
              title="基本配置"
              class="iss-card"
              :body-style="{ paddingBottom: 0 }"
              style="margin-bottom: 10px"
            >
              <a-row :gutter="20">
                <a-col :xs="24" :md="12">
                  <a-form-item label="SMTP服务器地址" v-bind="emailRules.host">
                    <a-input
                      v-model:value="email.host"
                      placeholder="请输入SMTP服务器地址"
                    />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12">
                  <a-form-item label="SMTP服务器端口" v-bind="emailRules.port">
                    <a-input
                      v-model:value="email.port"
                      placeholder="请输入SMTP服务器端口"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="20">
                <a-col :xs="24" :md="12">
                  <a-form-item label="发送账号" v-bind="emailRules.account">
                    <a-input
                      v-model:value="email.account"
                      placeholder="请输入邮件发送账号"
                    />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12">
                  <a-form-item label="账号密码" v-bind="emailRules.password">
                    <a-input-password
                      v-model:value="email.password"
                      placeholder="请输入账号密码"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="20">
                <a-col :xs="24" :md="12">
                  <a-form-item label="是否需要加密连接(SSL)">
                    <a-switch
                      v-model:checked="email.isEncrypt"
                      checked-children="是"
                      un-checked-children="否"
                    />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12">
                  <a-form-item label="是否忽视TLS">
                    <a-switch
                      v-model:checked="email.ignoreTls"
                      checked-children="是"
                      un-checked-children="否"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-card>
            <a-card
              title="发送人配置"
              class="iss-card"
              :body-style="{ paddingBottom: 0 }"
              style="margin-bottom: 10px"
            >
              <a-form-item label="邮件发送人" v-bind="emailRules.senderName">
                <a-input
                  v-model:value="email.senderName"
                  placeholder="请输入邮件发送人"
                />
              </a-form-item>
            </a-card>
            <a-card
              title="高级配置"
              class="iss-card"
              :body-style="{ paddingBottom: 0 }"
              style="margin-bottom: 10px"
            >
              <a-form-item
                label="统一邮件发送地址"
                v-bind="emailRules.unifiedServiceAddress"
              >
                <a-input
                  v-model:value="email.unifiedServiceAddress"
                  placeholder="请输入统一邮件发送地址"
                />
              </a-form-item>
            </a-card>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="sms" tab="短信配置">
          <a-form layout="vertical">
            <a-card
              title="基本配置"
              class="iss-card"
              :body-style="{ paddingBottom: 0 }"
              style="margin-bottom: 10px"
            >
              <a-form-item
                label="通道"
                v-bind="emailRules.unifiedServiceAddress"
              >
                <a-select
                  v-model:value="email.unifiedServiceAddress"
                  placeholder="请选择通道"
                >
                  <a-select-option key="" value=""></a-select-option>
                </a-select>
              </a-form-item>
            </a-card>
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="wechat" tab="微信配置">
          <a-form layout="vertical" class="iss-form">
            <a-card
              title="基本配置"
              class="iss-card"
              :body-style="{ paddingBottom: 0 }"
              style="margin-bottom: 10px"
            >
              <a-row :gutter="20">
                <a-col :xs="24" :md="12">
                  <a-form-item
                    label="公众号名称"
                    v-bind="emailRules.serviceName"
                  >
                    <a-input
                      v-model:value="email.serviceName"
                      placeholder="请输入公众号名称"
                    />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12">
                  <a-form-item
                    label="微信开发者对接地址"
                    v-bind="emailRules.password"
                  >
                    <a-input
                      v-model:value="email.password"
                      placeholder="请输入微信开发者对接地址"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="20">
                <a-col :xs="24" :md="12">
                  <a-form-item
                    label="公众号类型"
                    v-bind="emailRules.serviceType"
                  >
                    <a-radio-group v-model:value="emailRules.serviceType">
                      <a-radio value="service">服务号</a-radio>
                      <a-radio value="media">订阅号</a-radio>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12">
                  <a-form-item label="验证状态" v-bind="emailRules.account">
                    <a-switch
                      v-model:checked="email.isEncrypt"
                      checked-children="是"
                      un-checked-children="否"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="20">
                <a-col :span="24">
                  <a-form-item label="Token">
                    <a-input
                      v-model:value="email.serviceType"
                      placeholder="请输入Token"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="20">
                <a-col :xs="24" :md="12">
                  <a-form-item label="APPId">
                    <a-input
                      v-model:value="email.serviceType"
                      placeholder="请输入APPId"
                    />
                  </a-form-item>
                </a-col>
                <a-col :xs="24" :md="12">
                  <a-form-item label="APPSecret">
                    <a-input
                      v-model:value="email.serviceType"
                      placeholder="请输入APPSecret"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-card>
            <a-card
              title="高级配置"
              class="iss-card"
              :body-style="{ paddingBottom: 0 }"
              style="margin-bottom: 10px"
            >
              <a-form-item
                label="统一微信服务地址"
                v-bind="emailRules.unifiedServiceAddress"
              >
                <a-input
                  v-model:value="email.unifiedServiceAddress"
                  placeholder="请输入统一微信服务地址"
                />
              </a-form-item>
            </a-card>
          </a-form>
        </a-tab-pane>
      </a-tabs>
      <div class="ta-right">
        <a-space>
          <a-button type="primary" @click="handleClick"> 保存 </a-button>
          <a-button @click="handleClickByReset">重置</a-button>
        </a-space>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { reactive, ref, toRaw } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  Card,
  Col,
  Form,
  message,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
} from 'ant-design-vue';
import IssImageUpload from '@/components/imageUpload';
import { mobileRegExp } from '@/utils/validation';
import enterpriseApi from '@/api/enterprise';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    ASpace: Space,
    ACard: Card,
    ASwitch: Switch,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    IssImageUpload,
  },
  setup() {
    const required = { required: true, message: '不能为空' };
    const route = useRoute();
    const store = useStore();
    const activeKey = ref('email');
    let tempInfo = {};
    let tempEmail = {};
    const info = reactive({});
    const infoForm = Form.useForm(info, {
      name: [required],
      address: [required],
      phone: [
        required,
        { pattern: mobileRegExp, message: '请输入正确的电话号码' },
      ],
      email: [required, { type: 'email', message: '请输入正确的邮箱地址' }],
      logo: [required],
    });
    const email = reactive({});
    const emailForm = Form.useForm(email, {
      host: [required],
      port: [required],
      account: [required],
      password: [required],
      senderName: [required],
      unifiedServiceAddress: [required],
    });
    const initInfo = () =>
      enterpriseApi
        .getInfo(route.path, store.state.account.tenant)
        .then(data => {
          tempInfo = data;
          Object.assign(info, { ...tempInfo });
        });
    const initEmail = () =>
      enterpriseApi
        .getEmail(route.path, store.state.account.tenant)
        .then(data => {
          console.log(data);
        });
    initInfo();
    initEmail();

    return {
      activeKey,
      info,
      infoRules: infoForm.validateInfos,
      email,
      emailRules: emailForm.validateInfos,
      handleClickByInfo: () =>
        infoForm.validate().then(() =>
          enterpriseApi
            .updateInfo('enterprise:updateinfo', toRaw(info))
            .then(() => {
              message.success('操作成功');
              initInfo();
            })
        ),
      handleClickByInfoReset: () => Object.assign(info, { ...tempInfo }),
      handleClick: () =>
        infoForm.validate().then(() => {
          // enterpriseApi
          //   .updateInfo('enterprise:updateinfo', toRaw(info))
          //   .then(() => {
          //     message.success('操作成功');
          //     initInfo();
          //   });
        }),
      handleClickByReset: () => {
        switch (activeKey.value) {
          case 'email':
            Object.assign(email, { ...tempEmail });
            break;

          default:
            break;
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-form {
  max-height: calc(100vh - 196px);
  overflow-y: auto;
}
</style>
